import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef,
  Injectable, PLATFORM_ID, Inject, Input
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnDestroy {
  @Input() videourl: any = "";

  @ViewChild("videoModal", { static: false }) VideoModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public url: any;
  public isIEOrEdge: boolean;
  public main1_2 = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private dom: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/0bRwb8nJq6w");
  }


  openModal(main?) {

    this.isIEOrEdge = false;
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    this.main1_2 = false;

    if (main == "main2") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/cTnBct_Ws0Q");
    } else if (main == "main2_2") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/PC77ZoOzAtE");
    } else if (main == "main2_3") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/HWJnObzobjU");
    } else if (main == "main2_4") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/CRQleGPhOfk");
    } else if (main == "main2_5") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/exg0mk5Hums");
    } else if (main == "main2_6") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/Nbd7xB5KiOU");
    } else if (main == "main2_7") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/jPnQlHJ5ohU");
    } else if (main == "main2_8") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/2z4_hFjZGNk");
    }
    else if (main == "main2_9") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/lhJlXpJOPXM");
    }
    else if (main == "main2_10") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/UdSvU0S-xro");
    }
    else if (main == "main2_11") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/rhSihcBE3yE");
    }
    else if (main == "main2_12") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/upa34N1oiHI");
    }
    else if (main == "main2_13") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/frous99dAO0");
    }
    else if (main == "main3_1") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/3KRvo4QKEFw");
    }
    else if (main == "main3_2") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/8zMRAWr66e0");
    }
    else if (main == "main3_3") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/s9AFQieOB34");
    }
    else if (main == "main3_4") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/5GE2qaQ8by8");
    }
    else if (main == "main3_5") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/9zhyCJL-GT0");
    }
    else if (main == "ikeyo_1") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/OX432iAKLg8");
    }
    else if (main == "ikeyo_2") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/5euojSO0l9c");
    }
    else if (main == "main1_2") {
      this.url = this.dom.bypassSecurityTrustResourceUrl("https://firebasestorage.googleapis.com/v0/b/finemarket-2c0c6.appspot.com/o/walk2.mp4?alt=media&token=691f7eeb-e4a7-4f9c-be66-7c652c6a73d7");
      this.main1_2 = true;
    }

    else {
      this.url = this.dom.bypassSecurityTrustResourceUrl(main);
    }
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.VideoModal, {
        size: 'xl',
        ariaLabelledBy: 'Video-Modal',
        centered: true,
        windowClass: 'modal fade video-modal'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }


}
