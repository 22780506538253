import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public noshow1: boolean = false;
  public noshow2: boolean = false;
  public noshow3: boolean = false;
  public popup = true;
  public popup1 = false;
  public popup2 = false;
  public popup3 = false;
  public popup1_href = "www.naver.com";
  public popup1_src = "assets/images/popup20230829.jpg";

  public popup2_href = "www.google.co.kr";
  public popup2_src = "assets/images/popup20230829.jpg";

  public cache_date;

  data = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private api: APIService,
    private modalService: NgbModal) { }

  ngOnInit(): void {



  }

  ngAfterViewInit(): void {
    let d = new Date();
    this.cache_date = (d.getMonth() + 1) + "-" + d.getDate();
    this.getData();
    console.log(this.cache_date);

    let no1 = localStorage.getItem("noshow1");
    let no2 = localStorage.getItem("noshow2");
    let no3 = localStorage.getItem("noshow3");
    //let no2 = this.cache_date;

    /* if(!no1 || !no2 || !no3 || no1 != this.cache_date || no2 != this.cache_date || no3 != this.cache_date){ */
    /* if (!no1 || no1 != this.cache_date) {
      if (!this.popup) {
        this.modalService.dismissAll();
      } else {
        this.openModal();
      }

      console.log(no2);
      console.log(this.cache_date);
      if (no1 == this.cache_date) this.popup1 = false;
      if (no2 == this.cache_date) this.popup2 = false;
      if (no2 == this.cache_date) this.popup2 = false;
    } */
  }

  getData() {
    let test = [];
    this.api.getMainPopup().subscribe(async (data) => {
      console.log(data);
      this.data = data;
      if (this.data.length > 0) {
        let idx = 1;
        this.data.forEach((r, i) => {
          console.log(r);
          let chk_no = localStorage.getItem(r.fileLink);
          if (r.view && chk_no != this.cache_date) {
            if (idx === 1) {
              this.popup1 = true;
              this.popup1_href = r.link;
              this.popup1_src = r.fileLink;
              this.openModal();
            }
            if (idx === 2) {
              this.popup2 = true;
              this.popup2_href = r.link;
              this.popup2_src = r.fileLink;
            }
            idx++;
          }
        })
      }

    })
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, {
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  changecheck(event, src) {
    console.log(src);
    console.log(event.target.id);
    if (event.target.checked) {
      localStorage.setItem(src, this.cache_date);
    } else {
      localStorage.setItem(src, "");
    }
  }

  dismiss(e) {
    if (e == "popup2") {
      this.popup2 = false;
    } else if (e == "popup1") {
      this.popup1 = false;
    } else if (e == "popup3") {
      this.popup3 = false;
    }

    if (!this.popup1 && !this.popup2 && !this.popup3) {
      this.modalService.dismissAll();
    }
  }

}

