<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-3">
                    <div class="row">
                        <div class="col-12" *ngFor="let image of product?.images">
                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                   [lazyLoad]="image" 
                                    class="img-fluid">
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="product-right">
                        <h4><img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                            [lazyLoad]="profile_img" 
                             class="profile_img">
                             [{{product?.company}}] <b>{{username}}</b>&nbsp;
                             <a href="{{httpremove(product?.kakao_plus)}}" class="kakaoicon" *ngIf="product?.kakao_plus" target="_blank">
                                <img  src="assets/images/icon/kakaoicon.png"/>
                             </a>
                            </h4>
                        <h2>제품명 - {{product?.title }} </h2>
    
                        <span class="tag" *ngFor="let cat of product.category">#{{cat.name}}</span>
    
                        <div class="product-buttons text-right">
                            <div class="receipt">
                                <span  *ngIf="product.receipt=='true'" >계산서 발행 가능</span>
                                <span  *ngIf="product.receipt=='false'">계산서 발행 불가</span>
                                </div>
                        <div class="mb-3">
                            <h2>{{numberComma(product?.price)}}원</h2>
                        </div>
                            <div class="nstore">
                            <a href="{{httpremove(product.store)}}" *ngIf="product?.store && product?.sold_out=='false'"><img  src="assets/images/nbutton.png"/></a>
                            <span *ngIf="!product?.store && product?.sold_out=='false'" class="touch_tel">작가 문의 후 이용 가능</span>              
                            <span *ngIf="product?.sold_out=='true'" class="touch_tel">판매 종료</span>
                            </div>
                            
                        </div>                    
                        <div class="border-product">
                            <h6 class="product-title">판매자 정보</h6>
                            <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{addr}}</p>
                            <p><i class="fa fa-envelope" aria-hidden="true"></i> {{comp_email}}</p>
                            <p><i class="fa fa-phone-square" aria-hidden="true"></i> {{phonedash(product.company_tel)}}</p>
                        </div>
    
    
                    </div>
                    <section class="tab-product m-0">
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                            <ngb-tabset [type]="'tabs'" class="nav-material">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><img  src="assets/images/note.png" class="detail_img"/> 상세설명</ng-template>
                                    <ng-template ngbTabContent>
                                        <div [ngxSummernoteView]="product.description"></div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                            </div>
                        </div>
                    </section>
    
                </div>
            </div>
        </div>
    </div>
</ng-template>