import { Component, Injectable, PLATFORM_ID, Inject, enableProdMode  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import proxyPolyfillFunc from 'proxy-polyfill/proxy.min.js';
import cssVars from 'css-vars-ponyfill'
import {Market} from 'src/app/models/market'
import {User} from 'src/app/models/user'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

declare global {
  interface Window {
    WebComponents: {
      ready: boolean;
    };
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public user :User;
  public market : Market;
  name = 'Angular';

  myObj = {
    a : 'Hallo',
    b : 'xxxxx'
  }
  
  _time;
  get data() {
    return this._time;
  }
  
  
  proxy;
  // For Progressbar
/*   loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  ); */
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,
    

    ) 
    {
     if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
    const handler = {
      get: function(obj, prop) {
        console.log('get', prop);
        if (prop in obj) {
          return obj[prop];
        }
        throw new Error(`Getter ${prop} in Object not found.`);
      },
      set: function(obj, prop, value) {
        if (prop in obj) {
          console.log(`Setting ${value} for Object.`);
          obj[prop] = value + ' at ' + new Date();
          // Indicate success
          return true;
        }
        throw new Error(`Setter ${prop} in Object not found`);
      }
    };
    
    let proxy;
    const myObj = this.myObj;
    if (window['Proxy']) {
      proxy = new Proxy(myObj, handler);
    } else {
      const ProxyPolyfill = proxyPolyfillFunc();
      proxy = new ProxyPolyfill(myObj, handler);
    }
    this.proxy = proxy;
    cssVars();

  }

}
