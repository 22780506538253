import { Injectable, NgZone } from '@angular/core';
import { APIService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/api/firestorage.service';
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { map, finalize } from "rxjs/operators";
import { HttpClient, HttpParams } from '@angular/common/http';

export interface mapdata {
  name: any;
  lat: any;
  lon: any;
  upperAddrName: any;
  middleAddrName: any;
  lowerAddrName : any;
  firstNo : any;
}
@Injectable()
export class InitUserProvider {
  private loggedInUser: any = {};
  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;
  tmapurl = "https://apis.openapi.sk.com/tmap/pois?version=1&format=json&callback=result&resCoordType=WGS84GEO&reqCoordType=WGS84GEO&count=5";
  films: Observable<any>;
  autocompleteItems = [];

  constructor(
    private storage: AngularFireStorage,
    private api: APIService,
    private storageServ: StorageService,
    private httpClient: HttpClient,
    private __zone: NgZone,

  ) {
  }

  public getUserData(): any {
    return this.loggedInUser;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.api.getAdminToken().subscribe(res => {
        resolve(true);
      }, err=>{
        resolve(true);
        console.log(err);
      });
    });
  }

  async setLoggedInUser(id) {
    //console.log("setlogginUser");
    
    await localStorage.setItem("user_id", id);
    //console.log('SetLoggedinUser', this.loggedInUser);
  }

  async setAdmin(id) {
    //console.log("setlogginUser");
    
    await localStorage.setItem("admin_id", id);
    //console.log('SetLoggedinUser', this.loggedInUser);
  } 

 onFileSelected(event){
  return new Promise((resolve, reject) => {
    var n = Date.now();

    const file = event.target.files[0];
    const filePath = `profile/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`profile/${n}`, file);
    task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            console.log("upload");
            console.log(url);
            resolve({file_pass : this.fb, status : 200});
          });
        })
      ).subscribe(url => {
        if (url) {
          console.log("usr");
          console.log(url);
          //resolve(url);
        }
      }, err =>reject({status:400, msg : err}));
    });
  }

  onFileSelected_mu(event){
    return new Promise((resolve, reject) => {
      var n = Date.now();
  
      const file = event;
      const filePath = `profile/${n}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(`profile/${n}`, file);
      task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              if (url) {
                this.fb = url;
              }
              console.log("upload");
              console.log(url);
              resolve({file_pass : this.fb, status : 200});
            });
          })
        ).subscribe(url => {
          if (url) {
            console.log("usr");
            console.log(url);
            //resolve(url);
          }
        }, err =>reject({status:400, msg : err}));
      });
    }  

  getThumbUrl(url,sizex, sizey){
    const urlarr = url.split("?");
    return new Promise((resolve, reject)=>{
      const thumb_path = urlarr[0]+"_"+sizex+"x"+sizey+"?"+urlarr[1];
      resolve({thumb_path : thumb_path});
    });
  }

  getLocalUrl(_imagePath): Promise<{ url: string; nativeUrl: string; }> {
    return new Promise((resolve, reject) => {
      const name = _imagePath.split('/');
      this.makeFileIntoBlob(_imagePath, name[name.length - 1]).then((image) => {
        resolve({ url: window.URL.createObjectURL(image), nativeUrl: _imagePath });
      }).catch(error => {
        reject(error);

      });
    });
  }

  makeFileIntoBlob(_imagePath, fileName) {
    return new Promise((resolve, reject) => {
      window['resolveLocalFileSystemURL'](_imagePath, (fileEntry) => {
        fileEntry['file']((resFile) => {
          const reader = new FileReader();
          reader.onload = (evt: any) => {
            const imgBlob: any = new Blob([evt.target.result], { type: 'image/jpeg' });
            imgBlob.name = fileName;
            resolve(imgBlob);
          };
          reader.onloadend = (evt: any) => {
            const imgBlob: any = new Blob([evt.target.result], { type: 'image/jpeg' });
            imgBlob.name = fileName;
            resolve(imgBlob);
          };

          reader.onerror = (e) => {

            reject(e);
          };

          reader.readAsArrayBuffer(resFile);
        }, (err) => {

          reject(err);
        });
      }, (err) => {
      });
    });
  }


  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  numberComma(number) {
    number = Math.round(number);
    //console.log(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


async searchOnChange(searchItem: string) {

    this.autocompleteItems = [];


    let tmapKey = "7041668c-9b6a-4ebb-8785-96f9e62bd088";

    if (searchItem.trim() != "") {
      let params = new HttpParams();
      params = params.append('appKey', tmapKey);
      params = params.append('searchKeyword', searchItem);

      this.films = this.httpClient.get(this.tmapurl, { params: params });
      this.__zone.run(() => {
        this.films.subscribe((tmapdata) => {
          //console.log(tmapdata.searchPoiInfo.pois);

          if (tmapdata.searchPoiInfo.pois.poi.length > 0) {
            tmapdata.searchPoiInfo.pois.poi.forEach((dongInfo) => {
              console.log(dongInfo);
              let mdata = {} as mapdata;
              mdata.name = dongInfo.name;
              mdata.lat = dongInfo.noorLat;
              mdata.lon = dongInfo.noorLon;
              mdata.upperAddrName = dongInfo.upperAddrName;
              mdata.middleAddrName = dongInfo.middleAddrName;
              mdata.lowerAddrName = dongInfo.lowerAddrName;
              mdata.firstNo = dongInfo.firstNo;
              this.autocompleteItems.push(mdata);
              console.log(mdata);
            });
          }
        });
      });
    }
  }

}
