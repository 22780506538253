import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	param?: {};
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: '생활시장화인소개', type: 'sub', megaMenu: false, active: false, badgeText: '이벤트', children: [
				{ path: '/pages/aboutus', title: '생활시장화인이란?', type: 'link' },
				{ path: '/pages/about2', title: '화인 온라인 직거래 마켓', type: 'link' },
				{ path: '/pages/lookbook', title: '화인 시민운영자', type: 'link' },
				{ path: 'https://living.hcf.or.kr/lmth/02_activity04.asp', title: '화성시 공방지도', type: 'extTabLink' },
			]
		},
		{
			path: '/pages/program', title: '프로그램 안내', type: 'sub', active: false, children: [
				{ path: '/pages/book', title: 'E-도록 보기', type: 'link', badge: false, badgeText: '준비중' },
				{ path: '/pages/program', title: '2023 생활시장화인', type: 'link', param: { filter: '2023fine' } },
				{ path: '/pages/program', title: '워킹스루형 거리전시 "걷다가,화인"', type: 'link', param: { filter: 'walk' } },
				{ path: '/pages/program', title: '생활문화 전시장 "IKEYO"', type: 'link', param: { filter: 'ikeyo' } },
				{ path: '/pages/program', title: '생활문화 늦깎이 "김길주가 간다"', type: 'link', param: { filter: 'kim' } }

			]
		},
		{
			path: '/pages/register', title: '마켓 참여하기', type: 'sub', active: false, children: [
				{ path: '/pages/register', title: '온라인 직거래 마켓', type: 'link', badgeText: '이벤트진행중' },
				{ path: 'http://offline.xn--9g4b1fm1cba410dyhq35gfa3e.kr/regInfo', title: '오프라인 마켓', type: 'extTabLink' },
				{ path: '/pages/login', title: '온라인 참여 신청 조회', type: 'link' },
				{ path: 'http://offline.xn--9g4b1fm1cba410dyhq35gfa3e.kr/market/checkMarket', title: '오프라인 참여 신청 조회', type: 'extTabLink' },
			]
		},
		{
			title: '화인 이벤트', type: 'sub', badge: false, badgeText: '준비중', active: false, children:
				[
					{ path: '/pages/2021snsevent', title: '화인을 찾아라', type: 'link', badge: true, badgeText: '종료된 이벤트' },
					{ path: '/pages/snsevent', title: '생활문화제품 등록 이벤트', type: 'link', badge: false, badgeText: '종료된 이벤트' },
					// { path: '/pages/snsevent2', title: '화인을 찾아라', type: 'link', badge: false, badgeText: '종료된 이벤트' },
					{ path: '/pages/event2', title: 'K-핸드메이드페어 무료입장권 이벤트', type: 'link', badge: false, badgeText: '종료된 이벤트' }
				]


		},
		{
			title: '게시판', type: 'sub', active: false, children: [
				{ path: '/pages/board', title: '공지사항', type: 'link' },
				{ path: '/pages/faq', title: '자주하는 질문', type: 'link' },
				{ path: '/pages/freeboard', title: '자유게시판', type: 'link' },
				{ path: '/pages/gallery-new', title: '갤러리', type: 'link' },
				{ path: '/pages/gallery', title: '갤러리(구)', type: 'link' },

			]
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
				{
					title: 'mens fashion', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'top', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'shirts', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' }
					]
				},
				{
					title: 'women fashion', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'dresses', type: 'link' },
						{ path: '/home/fashion', title: 'skirts', type: 'link' },
						{ path: '/home/fashion', title: 'westarn wear', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'bottom wear', type: 'link' }
					]
				},
			]
		},
		{
			title: 'bags', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'shopper bags', type: 'link' },
				{ path: '/home/fashion', title: 'laptop bags', type: 'link' },
				{ path: '/home/fashion', title: 'clutches', type: 'link' },
				{
					path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'purses', type: 'link' },
						{ path: '/home/fashion', title: 'wallets', type: 'link' },
						{ path: '/home/fashion', title: 'leathers', type: 'link' },
						{ path: '/home/fashion', title: 'satchels', type: 'link' }
					]
				},
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'sport shoes', type: 'link' },
				{ path: '/home/fashion', title: 'formal shoes', type: 'link' },
				{ path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
				{ path: '/home/fashion', title: 'caps and hats', type: 'link' },
				{ path: '/home/fashion', title: 'precious jewellery', type: 'link' },
				{
					path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'necklaces', type: 'link' },
						{ path: '/home/fashion', title: 'earrings', type: 'link' },
						{ path: '/home/fashion', title: 'rings & wrist wear', type: 'link' },
						{
							path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
								{ path: '/home/fashion', title: 'ties', type: 'link' },
								{ path: '/home/fashion', title: 'cufflinks', type: 'link' },
								{ path: '/home/fashion', title: 'pockets squares', type: 'link' },
								{ path: '/home/fashion', title: 'helmets', type: 'link' },
								{ path: '/home/fashion', title: 'scarves', type: 'link' },
								{
									path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
										{ path: '/home/fashion', title: 'accessory gift sets', type: 'link' },
										{ path: '/home/fashion', title: 'travel accessories', type: 'link' },
										{ path: '/home/fashion', title: 'phone cases', type: 'link' }
									]
								},
							]
						}
					]
				},
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'makeup', type: 'link' },
				{ path: '/home/fashion', title: 'skincare', type: 'link' },
				{ path: '/home/fashion', title: 'premium beaty', type: 'link' },
				{
					path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'fragrances', type: 'link' },
						{ path: '/home/fashion', title: 'luxury beauty', type: 'link' },
						{ path: '/home/fashion', title: 'hair care', type: 'link' },
						{ path: '/home/fashion', title: 'tools & brushes', type: 'link' }
					]
				},
			]
		},
		{
			path: '/home/fashion', title: 'home & decor', type: 'link'
		},
		{
			path: '/home/fashion', title: 'kitchen', type: 'link'
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}

