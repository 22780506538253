<!--footer section -->
<footer [class]="class">
	<!-- 	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="section-b-space light-layout pt-5 pb-5">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">

						</div>
						<div class="footer-social">

							<ul>
								<li>
									<a href="https://www.facebook.com/finemarket0514/" target="_blank"><i
											class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="https://www.youtube.com/channel/UCuA-HTwc41V0Xv-1zydoHYA"
										target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
								</li>

								<li>
									<a href="https://www.instagram.com/finemarket_20/" target="_blank"><i
											class="fa fa-instagram" aria-hidden="true"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-4"></div>
				<div class="col-lg-4">

					<div class="footer-contant">
						<ul class="contact-details">
							<li><i class="fa fa-home"></i>(재)화성시문화재단</li>
							<li><i class="fa fa-phone"></i>대표번호.031-8059-1783</li>
							<li><i class="fa fa-fax"></i>FAX.031-8059-2976</li>
							<li><i class="fa fa-map"></i>주소. 경기도 화성시 노작로 134</li>
						</ul>

					</div>
				</div>


			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container pt-3 pb-3">
			<div class="row">
				<div class="col-xl-8 col-md-8 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> Copyright (C) 2020. 화성시문화재단. All rights
							reserved.
							<a routerLink="/pages/admin">
								<i class="fa fa-cog" aria-hidden="true"></i>
							</a>
						</p>
					</div>
				</div>
				<div class="col-xl-4 col-md-4 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a href="http://www.hscity.go.kr/intro.jsp" target="_blank">
									<img src="assets/images/icon/footer_logo01.jpg" alt="">
								</a>
							</li>
							<li>
								<a href="https://www.hcf.or.kr/hcf/1" target="_blank">
									<img src="assets/images/icon/footer_logo02.jpg" alt="">
								</a>
							</li>
							<li>
								<a href="https://living.hcf.or.kr/" target="_blank">
									<img src="assets/images/icon/footer_logo03.jpg" alt="">
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->