<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">

  <div class="top-header" >
    <div class="container">
        <div class="row">
          
          <div class="col-lg-5 text-right">
            
            <ul class="header-dropdown mobile_hide" *ngIf="admin_login">
              <li class="mobile-account" routerLink="/pages/admin/productlist">
                <i class="fa fa-user" aria-hidden="true"></i> 관리자페이지
              </li>

              <li class="mobile-account" (click)="admin_logout();">
                <i class="fa fa-sign-out" aria-hidden="true"></i> 관리자로그아웃
              </li>              
            </ul>

          </div>

          <div class="col-lg-7 text-right">


            <ul class="header-dropdown mobile_hide" *ngIf="login">
              <li class="mobile-account" routerLink="/pages/dashboard">
                <i class="fa fa-user" aria-hidden="true"></i> 작가페이지
              </li>

              <li class="mobile-account" (click)="logout();">
                <i class="fa fa-sign-out" aria-hidden="true"></i> 로그아웃
              </li>              
            </ul>

            <div class="theme-card theme-form mobile_hide pt-2" *ngIf="!login">
              

              <input type="text" class="form-control2 form-control mr-2" id="email" placeholder="아이디(이메일)" [(ngModel)] = "email">
                  <input type="password" class="form-control2 form-control" id="review" placeholder="비밀번호" [(ngModel)] = "password">
                  <button class="btn btn-solid hed-btn" (click)="login_action();" >로그인</button>
                  <button class="btn btn-solid hed-btn ml-2" routerLink="/pages/register" >등록하기</button>
                <br/>
                <a href="#" routerLink="/pages/findpassword" class="pwdtext">아이디/비밀번호찾기</a>
            </div>    



          </div>
        </div>
      </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a href="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
              
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</header>
