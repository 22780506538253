<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal *ngIf="popup">
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-md-6">
                        <div class="modal-bg" *ngIf="popup1">
                            <a [href]="popup1_href" target="_blank">
                                <div class="offer-content" style="cursor:pointer"><img [src]="popup1_src"
                                        class="img-fluid" style="width:100%; cursor:pointer" alt="">
                                </div>
                            </a>
                            <div class="row">
                                <div class="col-8 text-left pl-4 bot">
                                    <input type="checkbox" id="noshow1" [(ngModel)]="noshow1"
                                        (change)="changecheck($event, popup1_src)">
                                    <label for="noshow1">오늘 하루 창 열지 않기</label>
                                </div>
                                <div class="col-4 text-right pr-4 bot">
                                    <span (click)="dismiss('popup1')" style="cursor:pointer">닫기</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mo_absolute">
                        <div class="modal-bg" *ngIf="popup2">
                            <a [href]="popup2_href" target="_blank">
                                <div class="offer-content" style="cursor:pointer">
                                    <img [src]="popup2_src" class="img-fluid" style="width:100%; cursor:pointer" alt="">
                                </div>
                            </a>
                            <div class="row">
                                <div class="col-8 text-left pl-4 bot">
                                    <input type="checkbox" id="noshow2" [(ngModel)]="noshow2"
                                        (change)="changecheck($event, popup2_src)">
                                    <label for="noshow2">오늘 하루 창 열지 않기</label>

                                </div>
                                <div class="col-4 text-right pr-4 bot">
                                    <span (click)="dismiss('popup2')" style="cursor:pointer">닫기</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->