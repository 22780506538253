import { Component, OnInit, Input, HostListener } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {APIService} from 'src/app/services/api/api.service'
import {AuthenticationService} from 'src/app/services/api/firebase-authentication.service'
import {InitUserProvider} from 'src/app/services/inituser/inituser.service'
@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public login :boolean = false;
  public admin_login : boolean = false;
  public email;
  public password;
  constructor(   
    private toastrService: ToastrService,
    private router : Router,
    private auth : AuthenticationService,
    private inituser : InitUserProvider,
    private api : APIService,
    private route: ActivatedRoute
    ) {



  }

  ngOnInit(): void {
    let id = localStorage.getItem("user_id");
    if(id){
      this.login = true;
    }
    let admin = localStorage.getItem("admin_id");
    if(admin){
      this.admin_login = true;
    }
  }


  login_action(){
    this.api.login(this.email, this.password).subscribe(r=>{
      this.inituser.setLoggedInUser(r.uid);
      this.ngOnInit();

    }, err=>{
      if(err.code == "auth/argument-error"){
        this.toastrService.error("아이디/비밀번호를 확인해 주세요.");
      }
      else if(err.code == "auth/wrong-password"){
        this.toastrService.error("비밀번호를 확인해 주세요.");
      }
      else if(err.code == "auth/user-not-found"){
        this.toastrService.error("등록된 사용자가 없습니다.");
      }        
      else {
        console.log(err);
        this.toastrService.error("아이디/비밀번호를 확인해 주세요.)");
      }
    }
    )
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 300 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  logout(){
    localStorage.removeItem("user_id");
    this.login = false;
    this.router.navigate(['/']);
  }

  admin_logout(){
    localStorage.removeItem("admin_id");
    this.admin_login = false;
    this.router.navigate(['/']);
  }

}
