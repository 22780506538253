import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { AuthenticationService } from './firebase-authentication.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { RestClient } from './rest-client';
@Injectable({
  providedIn: 'root'
})
export class APIService extends RestClient {
  private id;

  constructor(
    protected http: HttpClient,
    private firestore: FirestoreService,
    private auth: AuthenticationService,
    private fireAuth: AngularFireAuth,

  ) {
    super('', http);
  }

  adminlogin(id, password): Observable<any> {
    return this.firestore.find('admin', ref => ref.where('login_id', '==', id).where('password', '==', password));

  }


  signUp(user, password): Observable<any> {

    return new Observable((observer) => {
      this.auth.createAccount(user.email, password)
        .then(User => {
          //console.log(User);
          user.id = User.uid;
          this.firestore.createWithId('clients', user).then(usr => {
            //console.log(usr);
            observer.next(user);
            localStorage.setItem("user_id", User.uid);
          }, err => {
            observer.error(err);
          });
        }).catch(err => {
          observer.error(err);
        });
    });

  }
  updateUser(id, data): Observable<any> {
    return from(this.firestore.update('clients', id, data));
  }

  forgotPassword(email: string): Observable<any> {
    return new Observable((observer) => {
      //console.log(email);
      this.auth.forgotPassoword(email).then(res => {
        observer.next(res);
      }).catch(err => {
        observer.error(err);
      })
    })
  }



  public login(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.fireAuth.auth.signInWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            observer.next(res.user);
          }
        })
        .catch(err => {
          observer.error(err);
        });
    });
  }

  chkemail(id: string): Observable<any> {
    return new Observable((observer) => {
      this.firestore.find('clients', ref => ref
        .where('email', '==', id)
      ).subscribe((clients: any) => {

        if (clients.length == 0) {
          observer.next({ status: "OK", message: "가입 가능" });
        } else {
          observer.next({ status: "ERR", message: "가입된 내역이 있습니다. 로그인해 주세요." })
        }
      }, err => {
        observer.error(err);
      });
    });
  }

  getEmail(name, phone): Observable<any> {
    return this.firestore.findOne('clients', ref => ref.where('phone', '==', phone).where('name', '==', name));
  }

  send_regphone(smsData): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('smsMessage', smsData))
  }

  addProduct(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('product', data))
  }

  addBoard(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('board', data))
  }

  addFreeboard(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('freeboard', data))
  }

  updateFreeboard(id, data): Observable<any> {
    return from(this.firestore.update('freeboard', id, data));
  }

  delFreeboard(id): Observable<any> {
    return from(this.firestore.delete('freeboard', id));
  }

  delFreeboardRe(par, id): Observable<any> {
    return from(this.firestore.delete('freeboard/' + par + "/replay", id));
  }


  addboardRe(id, data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('freeboard/' + id + "/replay", data))
  }




  addEvent(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('event', data))
  }

  getEventAll(type): Observable<any> {
    return this.firestore.find('event', ref => ref.where('event_name', '==', type).orderBy('createdAt', 'desc'));
  }

  getSurveyAll(): Observable<any> {
    return this.firestore.find('survey', ref => ref.orderBy('createdAt', 'desc'));
  }

  getEventUser(id): Observable<any> {
    return this.firestore.find('event', ref => ref.where('user_id', '==', id).orderBy('createdAt', 'desc'));
  }

  addEvent2(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('event2', data))
  }

  addEvent3(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('event3', data))
  }


  addEvent4(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('event4', data))
  }

  addsurvey(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('survey', data))
  }

  getEvent2User(phone): Observable<any> {
    return this.firestore.findOne('event2', ref => ref.where('phone', '==', phone));
  }

  getEvent3User(phone): Observable<any> {
    return this.firestore.findOne('event3', ref => ref.where('phone', '==', phone));
  }

  getEvent4User(phone): Observable<any> {
    return this.firestore.findOne('event4', ref => ref.where('phone', '==', phone));
  }

  getEvent2All(): Observable<any> {
    return this.firestore.find('event2', ref => ref.orderBy('createdAt', 'desc'));
  }

  getEvent3All(): Observable<any> {
    return this.firestore.find('event3', ref => ref.orderBy('createdAt', 'desc'));
  }

  getEvent4All(): Observable<any> {
    return this.firestore.find('event4', ref => ref.orderBy('createdAt', 'desc'));
  }


  getNotice(): Observable<any> {
    return this.firestore.find('board', ref => ref.where('show', '==', true).where('type', '==', 'notice').orderBy('createdAt', 'desc'));
  }

  getFreeboard(): Observable<any> {
    return this.firestore.find('freeboard', ref => ref.orderBy('createdAt', 'desc'));
  }

  getReplay(id): Observable<any> {
    return this.firestore.find('freeboard/' + id + "/replay", ref => ref.orderBy('createdAt', 'desc'));
  }

  getFaq(): Observable<any> {
    return this.firestore.find('board', ref => ref.where('show', '==', true).where('type', '==', 'faq').orderBy('createdAt', 'desc'));
  }

  getBoard(): Observable<any> {
    return this.firestore.find('board', ref => ref.orderBy('createdAt', 'desc'));
  }

  updateNotice(id, data): Observable<any> {
    return from(this.firestore.update('board', id, data));
  }

  delBoard(id): Observable<any> {
    return from(this.firestore.delete('board', id));
  }

  getNoticeDetail(id): Observable<any> {
    return this.addIdToObject(id, this.firestore.getOne('board', id));
  }




  updateProduct(id, data): Observable<any> {
    return from(this.firestore.update('product', id, data));
  }

  delProduct(id): Observable<any> {
    return from(this.firestore.delete('product', id));
  }

  getUser(clientId): Observable<any> {
    return this.addIdToObject(clientId, this.firestore.getOne('clients', clientId));
  }

  getUserProduct(userId): Observable<any> {
    return this.firestore.find('product', ref => ref
      .where('client_id', '==', userId).orderBy('createdAt', 'desc'));
  }

  getOneproduct(id): Observable<any> {
    return this.addIdToObject(id, this.firestore.getOne('product', id));
  }


  getProduct(): Observable<any> {
    /* return this.firestore.find('product', ref => ref.where('status','==','show').where('show','==',true).orderBy('createdAt', 'desc')); */
    return this.firestore.find('product', ref => ref.where('show', '==', true).orderBy('createdAt', 'desc'));
  }

  getMainSlider(): Observable<any> {
    /* return this.firestore.find('product', ref => ref.where('status','==','show').where('show','==',true).orderBy('createdAt', 'desc')); */
    return this.firestore.find('mainslider', ref => ref.orderBy('order', 'asc'));
  }

  getMainPopup(): Observable<any> {
    /* return this.firestore.find('product', ref => ref.where('status','==','show').where('show','==',true).orderBy('createdAt', 'desc')); */
    return this.firestore.find('mainpopup', ref => ref.orderBy('order', 'asc'));
  }

  addMainSlider(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('mainslider', data))
  }

  updateMainSlider(id, data): Observable<any> {
    return from(this.firestore.update('mainslider', id, data))
  }

  updateMainPopup(id, data): Observable<any> {
    return from(this.firestore.update('mainpopup', id, data))
  }

  delMainSlider(id): Observable<any> {
    return from(this.firestore.delete('mainslider', id));
  }

  addGalleryCate(data): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create('galleryCategory', data))
  }

  geGalleryCate(): Observable<any> {
    /* return this.firestore.find('product', ref => ref.where('status','==','show').where('show','==',true).orderBy('createdAt', 'desc')); */
    return this.firestore.find('galleryCategory', ref => ref.orderBy('createdAt', 'asc'));
  }

  delGalleryCate(id): Observable<any> {
    return from(this.firestore.delete('galleryCategory', id));
  }


  updateGalleryCate(id, data): Observable<any> {
    return from(this.firestore.update('galleryCategory', id, data))
  }


  getProduct_surv(): Observable<any> {
    /* return this.firestore.find('product', ref => ref.where('status','==','show').where('show','==',true).orderBy('createdAt', 'desc')); */
    return this.firestore.find('product', ref => ref.where('req_survey', '==', true).orderBy('reqsv_time', 'asc'));
  }

  getNewProduct(): Observable<any> {
    return this.firestore.find('product', ref => ref.orderBy('createdAt', 'desc'));
  }


  logout() {
    return this.auth.logout();
  }

  updateDriverData(id, driverData): Observable<any> {
    return from(this.firestore.update('drivers', id, driverData));
  }

  updateNoticeData(id, noticeData): Observable<any> {
    return from(this.firestore.update('notice', id, noticeData));
  }

  addDrvier(driverData): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create("drivers", driverData));
  }

  addNotice(noticeData): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create("notice", noticeData));
  }

  getDriver(driverId): Observable<any> {
    return this.addIdToObject(driverId, this.firestore.getOne('drivers', driverId));
  }

  getCustomer(clientId): Observable<any> {
    return this.addIdToObject(clientId, this.firestore.getOne('clients', clientId));
  }

  getAllDrivers(): Observable<any> {
    return this.firestore.find('drivers', ref => ref.orderBy('createdAt', 'desc'));
  }

  getAllNotices(): Observable<any> {
    return this.firestore.find('notice', ref => ref.orderBy('createdAt', 'desc'));
  }

  getAllCustomers(): Observable<any> {
    return this.firestore.find('clients', ref => ref.orderBy('createdAt', 'desc'));
  }

  getAllRides(): Observable<any> {
    return this.firestore.find('rides', ref => ref.orderBy('reserv_time', 'desc'));
  }

  delRide(id): Observable<any> {
    return from(this.firestore.delete('rides', id))
  }

  getAdminToken(): Observable<any> {
    return from(this.auth.signInAnonymously());
  }

  uploadImage(formData: FormData): Observable<any> {
    return this.upload(`photoUpload`, formData);
  }

  addIdToObject(id, obj: Observable<any>) {
    return new Observable((observer) => {
      if (id) {
        obj.subscribe(ref => {
          const newObj = ref;
          newObj.id = id;
          observer.next(newObj);
        }, err => {
          observer.error(err);
        });
      } else {
        observer.error({ message: 'No ID' });
      }
    });
  }

  snapshotToDataConverter(query: Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>) {
    return new Observable((observer) => {
      query
        .then(ref => {
          const obj = ref.data();
          obj.id = ref.id;
          observer.next(obj);
        }).catch(err => {
          observer.error(err);
        });
    });
  }


}
