import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public admin_login : boolean = false;
  public menuItems: Menu[];
  public login :boolean = false;


  constructor(private router: Router, public navServices: NavService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

  }

  ngOnInit(): void {

  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    let id = localStorage.getItem("user_id");
    if(id){
      this.login = true;
    }
    let admin = localStorage.getItem("admin_id");
    
    if(admin){
      this.admin_login = true;
    }
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
    let id = localStorage.getItem("user_id");
    if(id){
      this.login = true;
    }
    let admin = localStorage.getItem("admin_id");
    if(admin){
      this.admin_login = true;
    }
  }

  logout(){
    localStorage.removeItem("user_id");
    this.login = false;
    this.router.navigate(['/']);
  }

  admin_logout(){
    localStorage.removeItem("admin_id");
    this.login = false;
    this.router.navigate(['/']);
    window.location.reload();
  }

}
