<div *ngIf="!loader">
  <div class="img-wrapper">
    <a [routerLink]="['/shop/product/no/sidebar/', product.id]">
    <div class="front" style="background-image:url({{product.images[0]}})">
      

      
    </div>
  </a>
  </div>
  <div class="product-detail">
    <div>
      
      <a [routerLink]="['/shop/product/no/sidebar/', product.id]">
        <h6 style="font-size:12px;" *ngIf="product.type=='oneday' && product.dong">[{{product.gu}} {{product.dong}}]</h6>
        <h6>{{product.company}}</h6>
      </a>
      
      <h6><b>
        
        {{product.title}} 
        <div class="d-inline-block">  [{{ numberComma(product.price)}}원]</div>
      </b>
      </h6>
    
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

