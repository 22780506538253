<ng-template #videoModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <iframe [src]="url" allowfullscreen *ngIf="!isIEOrEdge || !main1_2"></iframe>
            <video controls height="630" *ngIf="isIEOrEdge && main1_2">

                <source [src]="url" type="video/mp4">

            </video>
        </div>
    </div>
</ng-template>
