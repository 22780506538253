// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  instagram_token: 'IGQWRQbmF0LVBYWDRxbWYyTTlONW1jY3FidWhVODgtaUtZANGt1dG9OWmhPclJRMl83NjJtRUZANeTdrMjBjM1VpZAFZAtekROcFhGUFBhdi1IR1JKazZAzV0RxNkM5TWVWejFoUDRpSHdLMHVOQQZDZD',
  stripe_token: 'STRIPE_PUBLISHABLE_KEY',
  paypal_token: 'PAYPAL_CLIENT_ID',
  apiKey: "AIzaSyCp08TEMwFjFOiqZiQbSHlSc96YSIbO-1g",
  authDomain: "finemarket-2c0c6.firebaseapp.com",
  databaseURL: "https://finemarket-2c0c6.firebaseio.com",
  projectId: "finemarket-2c0c6",
  storageBucket: "finemarket-2c0c6.appspot.com",
  messagingSenderId: "742480294204",
  appId: "1:742480294204:web:983786b75cb07d78b8ae39",
  externalApiUrl: 'https://offline.xn--9g4b1fm1cba410dyhq35gfa3e.kr/adm',
  apiUrl: 'https://offline.xn--9g4b1fm1cba410dyhq35gfa3e.kr/adm',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
/*
https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=IGQWRQLUNWY05HdjdxMVFlMl9HZA3JNSEhPcnM4d19qYnJ4T3pyLXlzVEdmclBzeHBSQmt5VEVNMmNhYzRINTlHMmc2bDk5TzFIYXlUMTFIS09LS1Rtd09TUnVISVp4VVNNQkdWbng1amVmdwZDZD

IGQWRNTVJGbEE1Qmxrd1ladzJpRXB2c0YtZA1VUREtxRmZAkc0xUTkZAyejA4cUdXeDNhV3JtU2JOTlMtcEtMc0ttSmZAoODFmRl92el93OC00NE91QmMwOW9FMzd4cl9UVndwcE5JSW9LS045dGZAjV2JyUEkxejBHb0UZD


curl -i -X GET "https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=5b3f294ae02040d1bd4264f31a7e9ac8&access_token=IGQVJYaF9DcUlxS1dBMTJFWnhUejZAIYlBHMUhwZAjdBUnZAPLVVjMS1OUVpZARFpnQ2dmQzhOOXVVSFBPLVJBeVB3S3AwUGlvVFplQjVaTkZACMGF3UGRWZAlhPWFBEUml6SVpZAYloxSi1n"
IGQVJXOUg1bGNoSFI0Q2JjWk12TDlMblFoejFpampDN04ySXAxTWp5RVpzRlBMSVpXVTZAVSEk0U0dXRk5DREpZAZAm12UndmNlNTTUVLQU1YZADdlLUUzQmtfN21OWklNRm4xYlh2aFJUQ1FBLTBXeGpsQgZDZD
*/


