import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "../../../classes/product";
import { ProductService } from '../../../../shared/services/product.service';
import {APIService} from 'src/app/services/api/api.service'
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../../../../shared/data/slider';
import {User} from 'src/app/models/user';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy  {

  @Input() product: Product;
  @Input() userinfo : User;

  @Input() currency: any;  
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public activeSlide: any = 0;
  public selectedSize: any;
  public profile_img = "";
  public addr = "";
  public comp_email = "";
  public username = "";
  public product2 : Product;
  public userinfo2 : User;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService,
    private api : APIService
    
    ) { 
      if(this.userinfo){


    }


    }

  ngOnInit(): void {

  }

  openModal() {

    console.log("this.userinfo");
    console.log(this.userinfo);
        if(this.product.client_id){
/*       this.api.getUser(this.product.client_id).subscribe(async da =>{

      }); */
    }



    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, { 
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  openModal2(prod, userInfo) {
    
    console.log("this.userinfo");
    console.log(userInfo);
    this.product = prod;
    this.userinfo = userInfo;

    this.product.company = userInfo.company;
    this.product.kakao_plus = userInfo.kakaotalk;
    this.product.company = userInfo.company;
    this.profile_img = userInfo.profile_img;
    this.addr = userInfo.addr.full;
    this.comp_email = userInfo.email;
    this.product.company_tel = userInfo.company_tel;
    this.username = userInfo.name;
    
    
        if(this.product.client_id){
/*       this.api.getUser(this.product.client_id).subscribe(async da =>{

      }); */
    }



    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, { 
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }
    }
    return uniqSize
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src
          }
        })
      }
    })
  }

  // Increament
  increment() {
    this.counter++ ;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  // Add to cart
  async addToCart(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if(status)
      this.router.navigate(['/shop/cart']);
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
  
  numberComma(number) {
    //number = number.replace(/-/gi, "");
    number = Math.round(number);
    //console.log(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

phonedash(num){
  if(num){
    num = num.toString();
    return num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
  }else{
    return ;
  }
}

httpremove(v){
  if(v){
    
    v =  v.toString().replace("https://", "").replace("http://","");
    v = "https:\/\/" + v.replace(/ /g,"");
    return v;
  }else{
    return;
  }
}

}
